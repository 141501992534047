import React, { Component, Fragment } from "react";
import Frame from "react-frame-component";
import classNames from "classnames";

import "./projectshowcase.css";

class ProjectShowcase extends Component {
  state = {
    isMobileView: false
  };

  handleMobileView = event => {
    this.setState({
      isMobileView: true
    });
  };

  handleDesktopView = event => {
    this.setState({
      isMobileView: false
    });
  };

  render() {
    const { html, previewImage, projectType, liveUrl } = this.props;

    let showcaseClass = classNames({
      showcaseFrame: true,
      mobileView: this.state.isMobileView
    });

    if (projectType === "email") {
      return (
        <Fragment>
          <div className="showcase-view-options d-none d-lg-block">
            <button className="change-view" onClick={this.handleMobileView}>
              View Mobile
            </button>
            <button className="change-view" onClick={this.handleDesktopView}>
              View Desktop
            </button>
          </div>
          <div className="showcase-container">
            <div className="project-showcase">
              <Frame initialContent={html} className={showcaseClass} />
            </div>
          </div>
        </Fragment>
      );
    }

    if (projectType === "web") {
      return (
        <Fragment>
          <div className="showcase-view-options d-none d-lg-block">
            <a href={liveUrl} target="_blank" rel="noopener noreferrer">
              <button className="change-view">View Live Demo</button>
            </a>
          </div>
          <div className="showcase-container">
            <div className="project-showcase">
              <img
                src={previewImage}
                className="web-project-showcase-img"
                alt="Preview"
              />
            </div>
          </div>
        </Fragment>
      );
    }
  }
}

export default ProjectShowcase;
