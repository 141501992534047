import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { writeUserData } from "../../firebase";
import moment from "moment";

import "./contactform.css";

const ContactFormSchema = Yup.object().shape({
  name: Yup.string()
    .min(1, "Please enter your name")
    .required("Please enter your name"),
  email: Yup.string()
    .email("Invalid email address format")
    .required("Email is required"),
  message: Yup.string()
    .min(3, "Please enter a message longer than 3 characters.")
    .required("Please enter a message longer than 3 characters.")
});

class ContactForm extends Component {
  state = {
    submitted: false
  };

  handleSubmit = (values, { props = this.props, setSubmitting, resetForm }) => {
    const currentDate = moment().format("MMMM Do YYYY, h:mm:ss a");
    writeUserData(values.email, values.message, currentDate, values.name);
    setSubmitting(false);
    resetForm();
    this.setState({ submitted: true });
    return;
  };

  render() {
    if (this.state.submitted)
      return (
        <div>
          <h6>Thanks - I'll get back to you within 24 hours.</h6>
        </div>
      );
    return (
      <Formik
        initialValues={{ name: "", email: "", message: "" }}
        validationSchema={ContactFormSchema}
        onSubmit={this.handleSubmit}
      >
        {({ touched, errors, isSubmitting }) => (
          <Form className="contact-form">
            <div className="form-group">
              <label htmlFor="email">Name</label>
              <Field
                type="name"
                name="name"
                placeholder="What's your name?"
                className={`form-control ${
                  touched.name && errors.name ? "is-invalid" : ""
                }`}
              />
              <ErrorMessage
                component="div"
                name="name"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <Field
                type="email"
                name="email"
                placeholder="Enter email"
                className={`form-control ${
                  touched.email && errors.email ? "is-invalid" : ""
                }`}
              />
              <ErrorMessage
                component="div"
                name="email"
                className="invalid-feedback"
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <Field
                type="text"
                name="message"
                placeholder="What can I do for you?"
                className={`form-control ${
                  touched.email && errors.email ? "is-invalid" : ""
                }`}
                component="textarea"
              />
              <ErrorMessage
                component="div"
                name="message"
                className="invalid-feedback"
              />
            </div>
            <button
              disabled={isSubmitting}
              type="submit"
              className="btn btn-primary"
            >
              {isSubmitting ? "Please wait..." : "Submit"}
            </button>
          </Form>
        )}
      </Formik>
    );
  }
}

export default ContactForm;
