import React from "react";
import Hero from "../components/Hero/Hero";
import AboutSection from "../components/AboutSection/AboutSection";
import SkillsSection from "../components/SkillsSection/SkillsSection";
import ProjectSection from "../components/ProjectSection/ProjectSection";

const Home = () => {
  return (
    <div>
      <Hero />
      <AboutSection />
      <SkillsSection />
      <ProjectSection />
    </div>
  );
};

export default Home;
