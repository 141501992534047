import React, { Component } from "react";
import ProjectItem from "../ProjectItem/ProjectItem";
import { ProjectFilters, FilterButton } from "./ProjectFilters/ProjectFilters";
import Spinner from "../Spinner/Spinner";
import { projectLike, projectUnlike } from "../../api/contentfulv2";

import client from "../../api/contentful";

import "./projectsection.css";

class ProjectSection extends Component {
  state = {
    projects: [],
    baseProjects: [],
    activeFilter: ""
  };

  async componentDidMount() {
    client
      .getEntries({
        content_type: "project",
        order: "fields.projectType"
      })
      .then(response =>
        this.setState({
          projects: response.items,
          baseProjects: response.items
        })
      )
      .catch(console.error);
  }

  handleProjectLike = async (id, previousLikes) => {
    // Update the likes for the clicked project on the client
    this.setState(prevState => {
      return {
        projects: prevState.projects.map(project =>
          project.sys.id === id
            ? {
                ...project,
                fields: {
                  ...project.fields,
                  likes: project.fields.likes + 1,
                  isLiked: true
                }
              }
            : project
        )
      };
    });

    // Send data to contentful

    projectLike(id);
  };

  handleProjectUnlike = async (id, previousLikes) => {
    // Update the likes for the clicked project on the client
    this.setState(prevState => {
      return {
        projects: prevState.projects.map(project =>
          project.sys.id === id
            ? {
                ...project,
                fields: {
                  ...project.fields,
                  likes: project.fields.likes - 1,
                  isLiked: false
                }
              }
            : project
        )
      };
    });

    // Send data to contentful

    projectUnlike(id);
  };

  handleFilterClick = event => {
    this.setState({
      projects: this.state.baseProjects
    });

    // Make a copy of the baseProjects array
    const projectList = [...this.state.baseProjects];

    // Filter the projects according to the button id
    const filteredProjects = projectList.filter(
      project => project.fields.projectType === event.target.id
    );

    // Set the projects state to the filtered array
    this.setState({
      projects: filteredProjects,
      activeFilter: event.target.id
    });
  };

  handleReset = event => {
    this.setState({
      projects: this.state.baseProjects,
      activeFilter: ""
    });
  };

  render() {
    const { projects, activeFilter } = this.state;

    return (
      <section id="projects">
        <div className="container projects-wrapper">
          <h3 className="projects-title">Projects &amp; Demos</h3>
          <p className="projects-subhead">
            A few examples of email and web projects I've built.
          </p>
          <p className="projects-subhead">
            All emails are created using a custom development and templating
            pipeline <br />
            ensuring desktop/mobile responsiveness and cross-client
            compatibility.
          </p>
          <ProjectFilters>
            <FilterButton
              label="show email"
              id="email"
              onClick={this.handleFilterClick}
              className={
                activeFilter === "email"
                  ? "btn filter-button active"
                  : "btn filter-button"
              }
            />
            <FilterButton
              label="show web"
              id="web"
              onClick={this.handleFilterClick}
              className={
                activeFilter === "web"
                  ? "btn filter-button active"
                  : "btn filter-button"
              }
            />
            <FilterButton
              label="show all"
              id="all"
              onClick={this.handleReset}
              className="btn filter-button"
            />
          </ProjectFilters>
          <div className="card-columns">
            {projects.length === 0 ? (
              <Spinner />
            ) : (
              projects.map(project => {
                return (
                  <ProjectItem
                    projectData={project}
                    key={project.sys.id}
                    id={project.sys.id}
                    handleProjectLike={this.handleProjectLike}
                    handleProjectUnlike={this.handleProjectUnlike}
                  />
                );
              })
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default ProjectSection;
