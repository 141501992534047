import React, { Component } from "react";
import ProjectItemLikes from "./ProjectItemLikes/ProjectItemLikes";
import { Link } from "react-router-dom";
import Fade from "react-reveal/Fade";

import "./projectitem.css";

class ProjectItem extends Component {
  state = {
    isVideoActive: false,
    isHovered: false
  };

  render() {
    return (
      <div className="card project-card">
        <Fade>
          <div className="project-item-wrapper">
            <div className="item-image-wrapper">
              <Link
                to={`/projects/${this.props.projectData.sys.id}`}
                style={{ textDecoration: "none" }}
              >
                <img
                  src={
                    this.props.projectData.fields.previewImage.fields.file.url
                  }
                  width="400"
                  alt=""
                />
              </Link>
            </div>

            <div className="item-image-overlay">
              <div className="item-image-overlay-content">
                <ProjectItemLikes
                  likes={this.props.projectData.fields.likes}
                  id={this.props.projectData.sys.id}
                  handleProjectLike={this.props.handleProjectLike}
                  isLiked={this.props.projectData.fields.isLiked}
                  handleProjectUnlike={this.props.handleProjectUnlike}
                />
              </div>
            </div>

            <div className="project-item-bottom">
              <div className="project-tags">
                {this.props.projectData.fields.tags.map((tag, index) => {
                  return (
                    <div key={index} className="project-tag">
                      {tag}
                    </div>
                  );
                })}
              </div>
              <div className="item-links d-flex justify-content-center justify-content-md-end align-items-center">
                <Link to={`/projects/${this.props.projectData.sys.id}`}>
                  <button className="button details">Details</button>
                </Link>
              </div>
            </div>
          </div>
        </Fade>
      </div>
    );
  }
}

export default ProjectItem;
