import React, { Component } from "react";

import "./skillssection.css";

class SkillsSection extends Component {
  state = {
    expanded: false
  };

  expandFrameworks = () => {
    const currentState = this.state.expanded;
    this.setState({
      expanded: !currentState
    });
  };

  render() {
    return (
      <section id="skills">
        <div className="container">
          <div className="skills-wrapper">
            <div className="row skills-title mb-4">
              <div className="col">
                <h3>Knowledge &amp; Skills in Detail</h3>
              </div>
            </div>
            <div className="row justify-content-center skills-row no-gutters">
              <div className="col-md-4 skills-column">
                <div className="skills-column-content">
                  <figure>
                    <i className="fas fa-at fa-3x" />
                  </figure>
                  <h5>Email Marketing Specialist</h5>
                  <p className="lead-in">
                    I design and code modern, responsive emails and templates
                    for use in marketing campaigns and marketing platforms.
                  </p>
                  <p className="section-header">Tools I enjoy using:</p>
                  <ul className="section-list">
                    <li>Advanced HTML, CSS3</li>
                    <li>Pardot</li>
                    <li>Mailchimp</li>
                    <li>Constant Contact</li>
                    <li>Sendgrid</li>
                    <li>Litmus</li>
                    <li>Autopilot</li>
                    <li>Adobe Creative Suite</li>
                    <li
                      className="frameworks-list"
                      onClick={this.expandFrameworks}
                    >
                      <p className="frameworks-title">
                        Email Development Frameworks
                      </p>
                      <div
                        className={
                          this.state.expanded
                            ? "frameworks expanded"
                            : "frameworks"
                        }
                      >
                        MJML
                        <br />
                        Foundation for Emails
                      </div>
                    </li>
                  </ul>
                  <p className="section-header">My Competencies:</p>
                  <ul className="section-list">
                    <li>Mobile First Email Development</li>
                    <li>Responsive Email Development</li>
                    <li>Hybrid Email Development</li>
                    <li>Custom Email Template Design</li>
                    <li>Custom Email Template Development</li>
                    <li>
                      Email Template System Design <br />
                      &amp; Development
                    </li>
                    <li>Cross-Client Render Testing</li>
                    <li>Dynamic Content &amp; Personalization</li>
                    <li>
                      Email Sending Platform Implementation &amp; Integration
                    </li>
                    <li>Email Automation</li>
                    <li>Drip Campaigns</li>
                    <li>Email Tracking &amp; Analytics</li>
                    <li>A/B, Multivariate Testing</li>
                    <li>Audience Segmentation</li>
                    <li>Copywriting</li>
                    <li>Deliverability Standards</li>
                    <li>CAN-SPAM/GDPR Compliance</li>
                  </ul>
                </div>
              </div>
              <div className="col-md-4 skills-column">
                <div className="skills-column-content">
                  <figure>
                    <i className="fas fa-cogs fa-3x" />
                  </figure>
                  <h5>Marketing Automation Specialist</h5>
                  <p className="lead-in">
                    I enjoy building modern marketing programs that deliver
                    results and empower organizations.
                  </p>
                  <p className="section-header">Tools I enjoy using:</p>
                  <ul className="section-list">
                    <li>Pardot</li>
                    <li>Salesforce CRM / Sales Cloud</li>
                    <li>Autopilot</li>
                    <li>Google Analytics</li>
                    <li>Google Tag Manager</li>
                    <li>Google Data Studio</li>
                  </ul>
                  <p className="section-header">My Competencies:</p>
                  <ul className="section-list">
                    <li>Drip Marketing</li>
                    <li>Lifecycle Marketing</li>
                    <li>Lead Generation/Capture</li>
                    <li>Lead Scoring</li>
                    <li>Lead Nurturing</li>
                    <li>Lead Management</li>
                    <li>List Imports</li>
                    <li>Data Sanitation</li>
                    <li>Performance Benchmarking &amp; Tracking</li>
                    <li>Event Capture</li>
                    <li>Prospect/Customer Segmentation</li>
                    <li>Funnel Design &amp; Optimization</li>
                    <li>Pardot/Salesforce Integration</li>
                    <li>Landing Page Design &amp; Implementation</li>
                    <li>Web/SEO/SEM Analytics</li>
                    <li>CRM Administration</li>
                    <li>Campaign Planning &amp; Strategy</li>
                    <li>Digital Asset Integration</li>
                  </ul>
                </div>
              </div>

              <div className="col-md-4 skills-column">
                <div className="skills-column-content">
                  <figure>
                    <i className="fas fa-code fa-3x" />
                  </figure>
                  <h5>Front-End Developer</h5>
                  <p className="lead-in">
                    I enjoy building beautiful websites, optimized landing
                    pages, and applications from scratch.
                  </p>
                  <p className="section-header">Languages I Speak:</p>
                  <ul className="section-list">
                    <li>HTML5</li>
                    <li>CSS3</li>
                    <li>SCSS</li>
                    <li>JavaScript</li>
                    <li>PHP</li>
                  </ul>
                  <p className="section-header">My Competencies:</p>
                  <ul className="section-list">
                    <li>Custom Landing Page Development</li>
                    <li>Wordpress Development</li>
                    <li>React Web App Development</li>
                    <li>API Integrations</li>
                  </ul>
                  <p className="section-header">My Development Tools:</p>
                  <ul className="section-list">
                    <li>Visual Studio Code</li>
                    <li>React</li>
                    <li>Wordpress</li>
                    <li>MongoDB</li>
                    <li>Google Firebase</li>
                    <li>Contentful</li>
                    <li>Netlify</li>
                    <li>Strapi</li>
                    <li>Digital Ocean</li>
                    <li>Node/NPM</li>
                    <li>Webpack</li>
                    <li>Grunt/Gulp</li>
                    <li>Github</li>
                    <li>Bootstrap</li>
                    <li>Semantic UI</li>
                    <li>Material UI</li>
                    <li>Bulma</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default SkillsSection;
