import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ProjectDetail from "./components/ProjectDetail/ProjectDetail";
import Home from "./pages/Home";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import NotFound from "./components/NotFound/NotFound";

import "./App.css";

class App extends Component {
  render() {
    return (
      <Fragment>
        <Router>
          <Navbar />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route path="/projects/:id" component={ProjectDetail} />
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </Router>
        <Footer />
      </Fragment>
    );
  }
}

export default App;
