import * as contentfulMgmt from "contentful-management";

//const contentful = require("contentful");

const client = contentfulMgmt.createClient({
  accessToken: process.env.REACT_APP_CONTENTFUL_MANAGEMENT_ACCESS_TOKEN
});

export async function projectLike(entryId) {
  const space = await client.getSpace(process.env.REACT_APP_SPACE_ID);
  const entry = await space.getEntry(entryId);
  entry.fields.likes["en-US"] = entry.fields.likes["en-US"] + 1;
  const updatedEntry = await entry.update();
  const publishedEntry = await updatedEntry.publish();
  const publishedEntryResponse = await space.getEntry(publishedEntry.sys.id);
  return publishedEntryResponse;
}

export async function projectUnlike(entryId) {
  const space = await client.getSpace(process.env.REACT_APP_SPACE_ID);
  const entry = await space.getEntry(entryId);
  entry.fields.likes["en-US"] = entry.fields.likes["en-US"] - 1;
  const updatedEntry = await entry.update();
  const publishedEntry = await updatedEntry.publish();
  const publishedEntryResponse = await space.getEntry(publishedEntry.sys.id);
  return publishedEntryResponse;
}
