import React from "react";

import "./projectFilters.css";

export const FilterButton = ({ label, onClick, id, className }) => {
  return (
    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-2 col-xl-2 text-center d-flex justify-content-center mx-5">
      <button onClick={onClick} id={id} className={className}>
        {label}
      </button>
    </div>
  );
};

export const ProjectFilters = props => {
  return (
    <div className="row justify-content-center mb-5">{props.children}</div>
  );
};
