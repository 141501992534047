import React from "react";
import { Link } from "react-router-dom";
import "./notfound.css";

const NotFound = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <div className="not-found-wrapper">
            <h1 className="text-center">Whoops, this page doesn't exist.</h1>
            <p className="text-center">
              <Link to="/">Click here to go back to the homepage.</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
