import React, { Component, Fragment } from "react";
import Spinner from "../Spinner/Spinner";
import ProjectShowcase from "./ProjectShowcase/ProjectShowcase";
import ReactMarkdown from "react-markdown";
import { Helmet } from "react-helmet";
import client from "../../api/contentful";

import "./projectdetail.css";

class ProjectDetail extends Component {
  state = {
    project: undefined,
    error: null
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    const projectId = this.props.match.params.id;

    try {
      const project = await client.getEntry(projectId);
      this.setState({
        project: project
      });
    } catch (error) {
      console.error(error);
      this.setState({
        error: error
      });
    }
  }

  handleBack = () => {
    this.props.history.goBack();
  };

  render() {
    return (
      <Fragment>
        <Helmet>
          <title>
            {!this.state.project
              ? "Loading..."
              : `Project Detail - ${this.state.project.fields.projectName}`}
          </title>
        </Helmet>
        <section id="project-detail">
          <div className="container-fluid">
            <div className="project-detail-wrapper">
              <div className="row">
                <div className="col-md-12 col-lg-4 offset-lg-1 col-xl-4 offset-xl-1">
                  <div className="project-data">
                    {this.state.project !== undefined ? (
                      <Fragment>
                        <h3 className="project-title">
                          {this.state.project.fields.projectName}
                        </h3>
                        <div className="project-description-long">
                          <ReactMarkdown>
                            {this.state.project.fields.longDescription}
                          </ReactMarkdown>
                        </div>
                      </Fragment>
                    ) : (
                      <Spinner />
                    )}
                    <div>
                      <button className="back-button" onClick={this.handleBack}>
                        Back
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 col-xl-6">
                  {this.state.project !== undefined ? (
                    <ProjectShowcase
                      html={this.state.project.fields.compiledCode}
                      previewImage={
                        this.state.project.fields.previewImage.fields.file.url
                      }
                      projectType={this.state.project.fields.projectType}
                      liveUrl={this.state.project.fields.liveUrl}
                    />
                  ) : (
                    <Spinner />
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </Fragment>
    );
  }
}

export default ProjectDetail;
