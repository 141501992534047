import React from "react";
import Fade from "react-reveal/Fade";
import TypedSection from "./TypedSection/TypedSection";

import "./hero.css";

const Hero = () => {
  return (
    <section id="hero">
      <div className="container-fluid hero-content">
        <div className="row">
          <div className="col-md-12">
            <Fade bottom>
              <h1 className="display-4 text-center">Hi, I'm Stephen</h1>
            </Fade>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <TypedSection
              strings={[
                "an email developer",
                "an email marketing specialist",
                "a marketing automation specialist",
                "a marketing developer",
                "a front-end developer",
                "a pug owner",
                "a music producer",
                "a BBQ enthusiast"
              ]}
            />
          </div>
        </div>
        <div className="row justify-content-center mt-4">
          <Fade bottom>
            <div className="d-flex justify-content-start hero-buttons">
              <a href="#contact">
                <button type="button" className="btn ml-3 mr-3 btn-green">
                  Contact Me
                </button>
              </a>
              <a
                href="https://res.cloudinary.com/dheck1ubc/image/upload/v1578600482/Docs/RustStephenEmailSpecialist-2020.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                <button type="button" className="btn ml-3 mr-3 btn-white">
                  View Resume
                </button>
              </a>
            </div>
          </Fade>
        </div>
      </div>
    </section>
  );
};

export default Hero;
