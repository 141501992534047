import React from 'react';

const NavItem = props => {
  return (
    <li key={props.index} className="nav-item">
      <a href={props.href} className="nav-link">
        {props.linkText}
      </a>
    </li>
  );
};

export default NavItem;
