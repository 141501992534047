import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import "./projectItemLikes.css";

const ProjectItemLikes = ({
  likes,
  id,
  handleProjectLike,
  isLiked,
  handleProjectUnlike
}) => {
  return (
    <div className="project-likes-wrapper">
      {!isLiked ? (
        <button
          className="btn btn-outline-primary"
          onClick={() => handleProjectLike(id)}
        >
          <FontAwesomeIcon icon={faThumbsUp} />
        </button>
      ) : (
        <button
          className="btn btn-outline-primary liked"
          onClick={() => handleProjectUnlike(id)}
        >
          {likes}
        </button>
      )}

      <Link to={`/projects/${id}`} className="btn btn-primary">
        Details
      </Link>
    </div>
  );
};

// className={
//   !isLiked === true
//     ? "btn btn-outline-primary"
//     : "btn btn-outline-primary liked"
// }

export default ProjectItemLikes;
