import React, { Component } from "react";
import Fade from "react-reveal/Fade";
import Typed from "typed.js";

import "./typedSection.css";

class TypedSection extends Component {
  constructor(props) {
    super(props);
    this.typedRef = React.createRef();
  }

  componentDidMount() {
    // If you want to pass more options as props, simply add
    // your desired props to this destructuring assignment.
    const { strings } = this.props;
    // You can pass other options here, such as typing speed, back speed, etc.
    const options = {
      strings: strings,
      typeSpeed: 50,
      backSpeed: 50,
      loop: true,
      backDelay: 1500
    };
    this.typed = new Typed(this.typedRef, options);
  }

  componentWillUnmount() {
    // Make sure to destroy Typed instance on unmounting
    // to prevent memory leaks
    this.typed.destroy();
  }

  render() {
    return (
      <Fade bottom>
        <div className="text-center">
          <span className="typedText">I'm </span>
          <span
            className="typedText"
            ref={typedRef => {
              this.typedRef = typedRef;
            }}
          />
        </div>
      </Fade>
    );
  }
}

export default TypedSection;
