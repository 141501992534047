import React, { Component } from "react";
import gaLogo from "../../assets/img/ga-icon.png";
import hsLogo from "../../assets/img/hs-icon.png";
import Reveal from "react-reveal/Reveal";
import "animate.css";
import "./aboutsection.css";

const KeySkill = props => {
  const { maxWidth } = props;
  return (
    <li className="key-skill" style={{ width: maxWidth }}>
      {props.children}
    </li>
  );
};

class AboutSection extends Component {
  render() {
    return (
      <section id="about">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="about-content">
                <h3>About Me</h3>
                <p>
                  Over the past 7 years, I've built my career and skill-set at
                  the intersection of marketing, technology, design, and
                  analysis. I've helped companies develop, execute, and optimize
                  hundreds of successful campaigns using modern marketing tools
                  and unique strategies that have generated hundreds of
                  thousands of revenue.
                </p>
                <p>
                  The teams and technologies I've worked with have helped me
                  develop a comprehensive knowledge of not only the platforms,
                  strategies, and marketing stacks that modern campaigns
                  leverage, but also the knowledge to create customized digital
                  assets and workflows that successful, scalable campaigns are
                  built on.
                </p>
                <p>
                  Pairing modern marketing knowledge with experience in web
                  development, analytics, design, and optimization puts me in a
                  unique position to plan, implement, and track today's digital
                  campaigns. I love working with a team, seeing a plan come
                  together, and generating results. Let's build something
                  together.
                </p>
                {/* <p>
                  Outside of work, my pug burger and I spend lots of time
                  exploring Austin and the rest of Texas. But, ATX's music, art,
                  and social scenes are pretty top-notch. Tinkering in tech has
                  always been a passion; I've always enjoyed building PCs,
                  programming hobby projects, producing music, and learning new
                  frameworks &amp; skills.
                </p> */}
                {/* <p>
                  I'm a results driven, versatile marketing &amp; advertising
                  professional with a talent for analysis and optimization, a
                  keen eye for design, and a diverse technical skill set who
                  understands the importance of distilling actionable,
                  business-relevant insights from any communications effort.
                  Experienced in identifying opportunities for growth and
                  outreach, quantifying potential benefits, and providing unique
                  solutions as well as data backed recommendations to justify
                  future efforts.
                </p> */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="skills-certs-wrapper">
                <div className="row">
                  <div className="col-12">
                    <div className="key-skills-content">
                      <h3>Key Skills and Experience</h3>
                      <ul style={{ overflowX: "hidden" }}>
                        <Reveal effect="fadeInLeft">
                          <KeySkill maxWidth="95%">
                            Email Development
                            <span className="key-skill-icon">6 years</span>
                          </KeySkill>
                          <KeySkill maxWidth="95%">
                            Email Marketing
                            <span className="key-skill-icon">6 years</span>
                          </KeySkill>
                          <KeySkill maxWidth="90%">
                            Marketing Automation
                            <span className="key-skill-icon">5 years</span>
                          </KeySkill>
                          <KeySkill maxWidth="85%">
                            Front End Web Development
                            <span className="key-skill-icon">4 years</span>
                          </KeySkill>
                        </Reveal>
                      </ul>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="key-skills-content">
                      <h3>Certifications</h3>
                      <div className="cert-row">
                        <div className="cert-img">
                          <img
                            src={gaLogo}
                            alt="Google Analytics Certification"
                          />
                        </div>
                        <div className="cert-name">
                          <p>
                            Google Analytics Individual Certification
                            <br />
                            <a
                              href="https://res.cloudinary.com/dheck1ubc/image/upload/v1568088553/Docs/Google_Analytics_Individual_Qualification___Google.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Certification
                            </a>
                          </p>
                        </div>
                      </div>
                      <div className="cert-row">
                        <div className="cert-img">
                          <img src={hsLogo} alt="Hubspot Certification" />
                        </div>
                        <div className="cert-name">
                          <p>
                            Hubspot Email Marketing Certification
                            <br />
                            <a
                              href="https://res.cloudinary.com/dheck1ubc/image/upload/v1568088553/Docs/dc97e88111964224a184b403b3747a05-1567746156000.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Certification
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default AboutSection;
