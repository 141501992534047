import React, { Component } from "react";
import { Link } from "react-router-dom";
import NavItem from "./NavItem/NavItem";
import "./navbar.css";

class Navbar extends Component {
  state = {
    navLinks: [
      {
        href: "/#about",
        linkText: "About"
      },
      {
        href: "/#skills",
        linkText: "Skills"
      },
      {
        href: "/#projects",
        linkText: "Projects"
      },
      {
        href:
          "https://res.cloudinary.com/dheck1ubc/image/upload/v1578600482/Docs/RustStephenEmailSpecialist-2020.pdf",
        linkText: "Resume"
      },
      {
        href: "/#footer",
        linkText: "Contact"
      }
    ],

    isScrolled: false
  };

  navRef = React.createRef();

  componentDidMount() {
    const navHeight = this.navRef.current.clientHeight;

    window.addEventListener("scroll", () => {
      if (window.scrollY > navHeight) {
        this.setState({ isScrolled: true });
      } else {
        this.setState({ isScrolled: false });
      }
    });
  }

  render() {
    return (
      <nav
        ref={this.navRef}
        className={`navbar fixed-top navbar-expand-md ${
          this.state.isScrolled ? "scrolled" : null
        }`}
      >
        <div className="container">
          <Link className="navbar-brand" to="/">
            Stephen Rust
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarCollapse"
            aria-controls="navbarCollapse"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars nav-toggle"></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <ul className="navbar-nav ml-auto">
              {this.state.navLinks.map((link, index) => {
                return (
                  <NavItem
                    key={index}
                    href={link.href}
                    linkText={link.linkText}
                  />
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
