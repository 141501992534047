import React from "react";
import ContactForm from "../ContactForm/ContactForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoffee,
  faHeart,
  faEnvelope,
  faPhone
} from "@fortawesome/free-solid-svg-icons";

import { faLinkedin, faTwitter } from "@fortawesome/free-brands-svg-icons";

import "./footer.css";

const Footer = props => {
  return (
    <section id="footer">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-6">
            <div id="contact" className="contact-info-wrapper">
              <h6>Stephen Rust</h6>
              <p>
                <span className="icon">
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                <a href="mailto:rust.stephen@gmail.com">
                  rust.stephen@gmail.com
                </a>
              </p>
              <p>
                <span className="icon">
                  <FontAwesomeIcon icon={faPhone} />
                </span>
                (210) 473-9423
              </p>
              <p>
                <span className="icon">
                  <FontAwesomeIcon icon={faLinkedin} />
                </span>
                <a href="https://www.linkedin.com/in/imstephenrust/">
                  imstephenrust
                </a>
              </p>
              <p>
                <span className="icon">
                  <FontAwesomeIcon icon={faTwitter} />
                </span>
                <a href="https://twitter.com/rust_stephen">rust_stephen</a>
              </p>
            </div>
          </div>
          <div className="col">
            <div className="footer-form-area">
              <h6>Contact me</h6>
              <ContactForm />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col copy">
            <p>
              Built with{"  "}
              <span>
                <FontAwesomeIcon icon={faCoffee} />
              </span>
              {"  "}
              and{"  "}
              <span>
                <FontAwesomeIcon icon={faHeart} />
              </span>
              {"  "}
              by Stephen Rust
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
